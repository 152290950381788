import { css } from '@emotion/react';

import ULLine from '../images/ul-line-icon.svg';
import { colors, fonts } from './constants';

const globalStyles = css`
  * {
    box-sizing: border-box;
    transition:
      background-color 0.3s,
      color 0.15s;
  }
  html,
  body {
    font-size: 100%;
    margin: 0;
    font-family: ${fonts.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    overflow-x: hidden;
  }

  a {
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  ul {
    padding-inline-start: 1.5rem;
    margin-bottom: 4.43rem;
  }

  li > ul,
  li > ol {
    margin-top: 1rem;
  }

  ul > li {
    list-style-image: url(${ULLine});
    padding-inline-start: 1rem;
    margin-bottom: 1.6875rem;
  }

  ol {
    padding-left: 0;
    list-style: none;
    counter-reset: item;
  }

  ol > li {
    counter-increment: item;
    margin-bottom: 1.6875rem;
    padding-inline-start: 2.3rem;
  }

  ol > li::marker {
    content: counter(item);
    color: ${colors.orange};
  }

  .swiper,
  .swipper-slide,
  .swiper-wrapper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }

  .single-image-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .gallery-image-wrapper {
    width: 100%;
    & > * {
      width: 100%;
    }
  }
`;

export default globalStyles;
