/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useApp } from '../../contexts/AppContext';
import { colors } from '../../emotion/constants';
import { Body, Button, Subheading } from '../atoms';
import Modal from './Modal';

const BoxedParagraph = styled(Body)`
  padding: 1rem;
  border: dotted 1px ${colors.gray_1};
  margin: 2rem 0;
`;

interface IConfirmationModal {
  onApprove: () => void;
}

export default function ConfirmationModal({ onApprove }: IConfirmationModal) {
  const { modalVisible, setModalVisible, mutationsLoading, proposalData } =
    useApp();

  return (
    <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
      <Subheading>¿Lo hacemos oficial?</Subheading>

      <Body>
        Un simple 'Aceptar' es todo lo que necesitamos para avanzar con la
        cotización y enviarte el contrato. Gracias por elegirnos. ¡Estamos
        emocionados por trabajar contigo!
      </Body>

      <BoxedParagraph>
        Enviaremos un correo de confirmación a:
        <br />
        <strong>{proposalData?.client?.email}</strong>
      </BoxedParagraph>

      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-top: 2.5rem;
        `}
      >
        <Button
          variant="ghost"
          disabled={mutationsLoading}
          onClick={() => setModalVisible(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="yellow"
          disabled={mutationsLoading}
          loading={mutationsLoading}
          onClick={onApprove}
        >
          Aprobar cotización
        </Button>
      </div>
    </Modal>
  );
}
